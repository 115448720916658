import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUniversities } from '../../store/universities/universitiesThunk';
import _ from 'lodash';
import { authActions } from '../../store/auth/auth';
import Clock from 'react-live-clock';

export default function TopNav() {
  const env = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PROXY_DEV : process.env.REACT_APP_PROXY_PROD;
  const dispatch = useDispatch();

  const universities = useSelector((state) => state.universities.universities.data);
  const status = useSelector((state) => state.universities.universities.status);
  const userjson = useSelector((state) => state.user.user.data);

  let user = '';

  if (_.isObject(userjson)) {
    user = userjson;
  } else {
    user = JSON.parse(userjson);
  }

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(authActions.handleLogout());
  };

  useEffect(() => {
    dispatch(getUniversities());
  }, [dispatch]);

  return (
    <>
      <div className="navbar-custom">
        <div className="container-fluid">
          <ul className="list-unstyled topnav-menu float-end mb-0">
            <li className="d-none d-lg-block">
              <ul className="clocks">
                <li className="clock">
                  <span>IL </span>
                  <Clock format={'LT'} ticking={true} timezone={'Asia/Jerusalem'} />
                </li>
                <li className="clock">
                  <span>MI </span>
                  <Clock format={'LT'} ticking={true} timezone={'America/New_York'} />
                </li>
                <li className="clock">
                  <span>LA </span>
                  <Clock format={'LT'} ticking={true} timezone={'America/Los_Angeles'} />
                </li>
              </ul>
            </li>
            <li className="d-none d-lg-block">
              <Link to="/search" className="clocks">
                <i className="fe-search"></i>
              </Link>
            </li>
            {/* <li className="d-none d-lg-block">
            <form className="app-search">
              <div className="app-search-box">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    id="top-search"
                    autocomplete="off"
                  />
                  <button className="btn input-group-text" type="submit">
                    <i className="fe-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </li>

          <li className="dropdown d-inline-block d-lg-none">
            <a
              className="nav-link dropdown-toggle waves-effect waves-light"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="fe-search noti-icon"></i>
            </a>
            <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
              <form className="p-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                  autocomplete="off"
                />
              </form>
            </div>
          </li> */}

            <li className="dropdown notification-list topbar-dropdown">
              <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                <img src={user.user_avatar} alt="user-avatar" className="rounded-circle" />
                <span className="pro-user-name ms-1">
                  {user.user_display_name}
                  <span className="ms-1 badge bg-info text-capitalize">{user.user_roles[0]}</span>
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome {user.user_first_name}!</h6>
                </div>

                {/* <div className="themes text-center">
                <i className="fas fa-sun me-2" onClick={()=>handleThemeSwitch("light")}></i>
                <i className="fas fa-moon" onClick={()=>handleThemeSwitch("dark")}></i>
              </div> */}

                <a href={env + '/wp-admin/profile.php'} target="_blank" rel="noreferrer" className="dropdown-item notify-item">
                  <i className="fe-user"></i>
                  <span>My Account</span>
                </a>

                {_.includes(user.user_roles, 'administrator') && (
                  <a href={env + '/sap'} target="_blank" rel="noreferrer" className="dropdown-item notify-item">
                    <i className="dripicons-gear"></i>
                    <span>Admin Panel</span>
                  </a>
                )}

                <div className="dropdown-divider"></div>

                <a href="#" onClick={(e) => handleLogout(e)} className="dropdown-item notify-item">
                  <i className="fe-log-out"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>

          <div className="logo-box">
            <a href="/" className="logo logo-light text-center">
              <span className="logo-sm">
                <img src="../img/logo-sm-w.svg" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="../img/logo-light.svg" alt="" height="25" />
              </span>
            </a>
            <a href="/" className="logo logo-dark text-center">
              <span className="logo-sm">
                <img src="../img/logo-sm-w.svg" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="../img/logo-dark.svg" alt="" height="25" />
              </span>
            </a>
          </div>

          <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
            <li>
              <a className="navbar-toggle nav-link" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </li>
          </ul>

          <div className="clearfix"></div>
        </div>
      </div>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link to="/" className="nav-link" id="topnav-dashboard" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="mdi mdi-view-dashboard me-1"></i>Dashboard
                  </Link>
                </li>

                {!_.includes(user.user_roles, 'subscriber') && (
                  <>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="topnav-layout" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="mdi mdi-school-outline me-1"></i> Universities <div className="arrow-down"></div>
                      </a>
                      <div className="dropdown-menu mega-dropdown-menu" aria-labelledby="topnav-ui">
                        <div className="row dropdown-item-box">
                          {status === 'success' &&
                            _.chunk(universities, 7).map((uni, i) => (
                              <div className="col col-lg-4 dropdown-item-box" key={i}>
                                {uni.map((university, index) => (
                                  <Link key={index} to={'/universities/' + university.id} className="dropdown-item">
                                    <img className="me-2" width="20" src={university.favicon} alt={university.slug} />
                                    {university.title}
                                  </Link>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="topnav-layout" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="mdi mdi-card-bulleted-settings-outline me-1"></i> Views <div className="arrow-down"></div>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="topnav-layout">
                        <Link to="/universities" className="dropdown-item">
                          All Universites
                        </Link>
                        <Link to="/brands" className="dropdown-item">
                          Brands
                        </Link>
                        <Link to="/catalogs" className="dropdown-item">
                          Catalogs/Ebooks
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="topnav-layout" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="mdi mdi-account-multiple-outline me-1"></i> Users <div className="arrow-down"></div>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="topnav-layout">
                        <Link to="/users" className="dropdown-item">
                          Users Managment
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link to="/watchdog" className="nav-link" id="topnav-watchdog" role="button" aria-haspopup="true" aria-expanded="false">
                        <i className="mdi mdi-dog me-1"></i>Watchdog
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
