import bootstrap from 'bootstrap';
import './assets/sass/main-dark.scss';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import AuthRoute from './AuthRoute';
import { useIdleTimer } from 'react-idle-timer';

//Template Layouts
import MainLayout from './containers/templates/MainLayout';

//Views
import Login from './components/views/Login';
import Dashboard from './components/views/Dashboard';
import University from './components/universities/University';
import Universities from './components/views/Universities';
import Brands from './components/views/Brands';
import Search from './components/views/Search';
import Releases from './components/views/Releases';
import UsersList from './components/Users/UsersList';
import Notfound from './components/views/Notfound';
import { authActions } from './store/auth/auth';
import Watchdog from './components/views/Watchdog';
import Catalogs from './components/views/Catalogs';

function App() {
  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.user.user.isLogin);
  const notification = useSelector((state) => state.ui.notification);
  const toast = useRef(null);

  const handleOnIdle = (event) => {
    dispatch(authActions.handleLogout());
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  };

  const Idel = useIdleTimer({
    timeout: 1000 * 60 * 60 * 9,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    if (notification) {
      toast.current.show({
        severity: notification.severity,
        summary: notification.summary,
        detail: notification.detail,
        life: notification.life,
      });
    }
  });

  return (
    <>
      {notification && <Toast ref={toast} />}
      <BrowserRouter>
        <Switch>
          <Route exact path="/login">
            {isLogin && <Redirect to="/" />}
            {!isLogin && <Login />}
          </Route>

          <Route>
            <MainLayout auth={isLogin}>
              <AuthRoute exact path="/" component={Dashboard} />
              <AuthRoute exact path="/universities/:id" component={University} />
              <AuthRoute exact path="/universities" component={Universities} />
              <AuthRoute exact path="/brands" component={Brands} />
              <AuthRoute exact path="/users" component={UsersList} />
              <AuthRoute exact path="/search" component={Search} />
              <AuthRoute exact path="/releases" component={Releases} />
              <AuthRoute exact path="/watchdog" component={Watchdog} />
              <AuthRoute exact path="/catalogs" component={Catalogs} />
            </MainLayout>
          </Route>

          <Route path="*" component={Notfound} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
