import {configureStore} from '@reduxjs/toolkit'
import universitiesSlice from './universities/universitiesThunk';
import uiSlice from './UI/ui';
import lpsSlice from './lps/lpsThunk';
import pagesSlice from './universitiesPages/pagesThunk';
import freemiumSlice from './freemium/freemiumThunk';
import userSlice from './auth/auth';
import usersSlice from './users/usersThunk';
import urlsSlice from './urls/urlsThunk';
import releasesSlice from './releases/releasesThunk';
import filesSlice from './files/filesThunk';
import leadsSlice from './watchdog/watchdogThunk';

const store = configureStore({
    reducer: {
        universities: universitiesSlice.reducer,
        ui: uiSlice.reducer,
        lps: lpsSlice.reducer,
        pages: pagesSlice.reducer,
        freemium: freemiumSlice.reducer,
        user: userSlice.reducer,
        users: usersSlice.reducer,
        urls: urlsSlice.reducer,
        releases: releasesSlice.reducer,
        files: filesSlice.reducer,
        leads: leadsSlice.reducer
    }
});

export default store;