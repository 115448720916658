import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getReleases } from "../../store/releases/releasesThunk";
import moment from "moment";

export default function Releases() {
  const dispatch = useDispatch();

  const releases = useSelector((state) => state.releases.releases.data);
  const status = useSelector((state) => state.releases.releases.status);

  useEffect(() => {
    dispatch(getReleases());
  }, [dispatch]);

  const releaseType = (type) => {
    if(type==="new"){
        return <span class="mb-1 badge bg-success text-uppercase">{type}</span>
    }else if(type==="change") {
        return <span class="mb-1 badge bg-info text-uppercase">{type}</span>
    }else{
        return <span class="mb-1 badge bg-danger text-uppercase">{type}</span>
    }
  }

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">SUM</a>
                    </li>
                    <li className="breadcrumb-item active">Release Notes</li>
                  </ol>
                </div>
                <h4 className="page-title">Release Notes</h4>
              </div>
            </div>
          </div>
          {status === "success" &&
            releases.map((release, i) => (
              <div className="row" key={i}>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div class="float-end">
                        <em>
                          Released on {moment(release.date).format("LLL")}
                        </em>
                      </div>
                      <h2>
                        {release.title.rendered}
                        {i === 0 && (
                            <>
                            <span class="ms-2 badge badge-soft-success">New</span>
                            <span class="ms-2 badge bg-light text-dark">Current Version</span>
                            </>
                        )}
                      </h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: release.content.rendered,
                        }}
                      ></p>
                      {release.acf.new_release.map((update) => (
                        <div>
                          {releaseType(update.release_type)}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: update.release_note,
                            }}
                          ></p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
