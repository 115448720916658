import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from 'react-tooltip';


export default function CopyToClipboardComp(props) {
  const copyText = "Click To Copy";
  const copiedText = "Copied!";

  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleHtml = (html , showIcon) => {
    if (html) {
      return(
        <span style={{ cursor: "pointer" }} data-for={props.identifier} data-tip={copied ? copiedText : copyText} dangerouslySetInnerHTML={{
          __html: props.text
        }}>
        </span>
      )
    }else{
      return(
        <span
          style={{ cursor: "pointer" }}
          data-for={props.identifier}
          data-tip={copied ? copiedText : copyText}
          
        >
          {showIcon ? <i className={showIcon}></i> : props.text}
        </span>
      )
    }
  }

  return (
    <>
      <CopyToClipboard onCopy={onCopy} text={props.text}>
        {handleHtml(props.html , props.showIcon)}
      </CopyToClipboard>
      <ReactTooltip
        place="right"
        id={props.identifier}
        getContent={[
          () => {
            return copied ? copiedText : copyText;
          },
          1000,
        ]}
      />
    </>
  );
}
