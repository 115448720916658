import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import _ from "lodash";


import { uiActions } from '../../store/UI/ui';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import UniversityCard from './UniversityCard';




export default function UniversitiesList() {

    const dispatch = useDispatch();

    const universities = useSelector(state => state.universities.universities.data)
    const status = useSelector(state => state.universities.universities.status)
    const viewType = useSelector(state => state.ui.view)

    const userjson = useSelector((state) => state.user.user.data);


    let user = "";

    if (_.isObject(userjson)) {
        user = userjson;
    } else {
        user = JSON.parse(userjson);
    }

    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState('');


    const reset = () => {
        setGlobalFilter('');
        dt.current.reset();
    }

    const handleViewType = (type) => {
        dispatch(uiActions.updateViewType(type));
    };

    const header = (
        <div className="table-header">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const faviconBodyTemplate = (rowData) => {
        return (
            <>
                <img alt="favicon" style={{ marginRight:  "1rem" }} src={rowData.favicon} width={30} />
            </>
        );
    }

    const userBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Webmaster</span>
                {rowData.user.avatar ? <img style={{border: '3px solid '+ rowData.brand.brand_colors[0].brand_color }} src={rowData.user.avatar} alt="user-pic" className="rounded-circle avatar-sm bx-shadow-lg" /> : <span className="rounded-circle avatar-sm bx-shadow-lg avatarNone" style={{ marginRight:  "1rem" }}>{getInitials(rowData.user.nickname)}</span>}
                <span className="ms-1">{rowData.user.nickname}</span>
            </>
        );
    }

    const websiteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Website</span>
                <a href={rowData.main_domain}>{rowData.main_domain}</a>
            </>
        );
    }

    const actionsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Actions</span>
                <Link to={"/universities/" + rowData.id} className="btn btn-primary waves-effect waves-light me-1"><i class="mdi mdi-file-document"></i> University Page</Link>
            </>
        );
    }


    const getInitials = (name) => {
        var names = name.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    return (
        <>
        {status === "success" && 
        
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                    <div className="float-end">
                        <i onClick={() => handleViewType("thumb")} className="dripicons-view-thumb cursor-pointer me-2"></i>
                        <i onClick={() => handleViewType("list")} className="dripicons-view-list-large cursor-pointer"></i>
                    </div>
                    <h4 className="header-title mb-3">Universities List <span className="badge bg-primary ms-2 text-uppercase">{universities.length}</span></h4>
                    {
                        viewType === "thumb" &&
                        <div className="universities">
                            <div className="universities-list row row-cols-xl-3 row-cols-xxl-4">
                                {universities.map((university) => <UniversityCard data={university} key={university.id}/>)}
                            </div>
                        </div>
                    }
                    {viewType === "list" &&
                        <div className="table-responsive datatable-filter-demo">
                            {<DataTable ref={dt} value={universities} paginator rows={10}
                                header={header} className="p-datatable-universities"
                                globalFilter={globalFilter} removableSort stripedRows resizableColumns columnResizeMode="fit" emptyMessage="No Universities found.">
                                <Column field="favicon" body={faviconBodyTemplate} style={{width:'5%'}} />
                                <Column field="title" header="University Name" sortable />
                                <Column field="main_domain" body={websiteBodyTemplate} header="Website" />
                                <Column field="user.nickname" body={userBodyTemplate} header="Webmaster" sortable style={{width:'15%'}}/>
                                <Column field="update" header="Updated At" sortable />
                                {!_.includes(user.user_roles, "subscriber") && (
                                <Column body={actionsBodyTemplate} header="Go To" style={{width:'20%'}}/>
                                )}
                            </DataTable>}

                        </div>
                    }
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}
