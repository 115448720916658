import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getUsers } from "../../store/users/usersThunk";
import ReactTooltip from 'react-tooltip';


export default function UsersList() {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users.data);
  const status = useSelector((state) => state.users.users.status);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");

  const reset = () => {
    setGlobalFilter("");
    dt.current.reset();
  };

  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Global Search"
        />
      </span>
    </div>
  );

  const userBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Avatar</span>
        <img
          style={{ border: "3px solid $lblue" }}
          src={rowData.avatar}
          alt="user-pic"
          className="rounded-circle avatar-sm bx-shadow-lg"
        />
      </>
    );
  };

  const managerBodyTemplate = (rowData) => {
    return (
      <>
        <ReactTooltip />
        <span className="p-column-title">Manager</span>
        <img
          style={{ border: "3px solid $lblue" }}
          src={rowData.manager.avatar}
          alt="user-pic"
          className="rounded-circle avatar-sm bx-shadow-lg"
          data-tip={rowData.manager.name}
        />

      </>
    );
  };

  const roleBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Role</span>
        <span className="badge bg-info text-capitalize">
          {rowData.roles}
        </span>
      </>
    );
  };

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">SUM</a>
                    </li>
                    <li className="breadcrumb-item active">Users</li>
                  </ol>
                </div>
                <h4 className="page-title">Users</h4>
              </div>
            </div>
          </div>
          {status === "success" && (
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="table-responsive datatable-filter-demo">
                    {
                      <DataTable
                        ref={dt}
                        value={users}
                        header={header}
                        className="p-datatable-universities"
                        globalFilter={globalFilter}
                        removableSort
                        stripedRows
                        resizableColumns
                        columnResizeMode="fit"
                        emptyMessage="No Universities found."
                      >
                        <Column
                          header=""
                          body={userBodyTemplate}
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="first_name"
                          header="First Name"
                          sortable
                        />
                        <Column field="last_name" header="Last Name" sortable />
                        <Column field="email" header="Email" sortable />
                        <Column field="phone" header="Phone" sortable />
                        <Column field="birthday" header="Birthday" sortable />
                        <Column field="address" header="Address" sortable />
                        <Column
                          field="manager.name"
                          body={managerBodyTemplate}
                          header="Manager"
                          sortable
                        />
                        <Column
                          field="roles"
                          body={roleBodyTemplate}
                          header="Role"
                          sortable
                        />
                      </DataTable>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
