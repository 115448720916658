import { useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export default function DashLps(props) {
    const lps = props.data

    const status = useSelector(state => state.lps.lps.status)


    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState('');

    const reset = () => {
        setGlobalFilter('');
        dt.current.reset();
    }

    const header = (
        <div className="table-header">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const statusBodyTemplate = (rowData) => {

        if (rowData.status === "publish") {
            return <span className="badge bg-success text-capitalize">{rowData.status}</span>;
        } if (rowData.status === "old-lp") {
            return <span className="badge bg-info text-capitalize">{rowData.status}</span>;
        } else {
            return <span className="badge bg-danger text-capitalize">{rowData.status}</span>;
        }

    }


    const urlBodyTemplate = (rowData) => {
        return <a href={rowData.link} target="_blank" rel="noreferrer">{rowData.link}</a>;
    }


    const typeBodyTemplate = (rowData) => {
        let str = rowData.template;
        let newStr = str.replace('views/lp', '').replace('.blade.php','').replace('-',' ');
        return <span className="badge bg-dark text-light text-capitalize">{newStr}</span>;
    }

    return (
        <>
        <h4>Website LPs List</h4>
        {status === "loading" && <ProgressSpinner />}
        {status === "success" &&
        <div className="table-responsive datatable-filter-demo"> 
            <DataTable ref={dt} value={lps} paginator rows={10}
                header={header} className="p-datatable-universities"
                globalFilter={globalFilter} removableSort stripedRows resizableColumns columnResizeMode="fit" emptyMessage="No Lps found.">
                <Column sortable field="title" header="Title" style={{ width: '15%' }}/>
                <Column sortable field="template" body={typeBodyTemplate} header="Template" style={{ width: '10%' }} />
                <Column sortable field="link" body={urlBodyTemplate} header="URL" />
                <Column sortable field="status" body={statusBodyTemplate} header="Status" style={{ width: '10%' }} />
                <Column sortable field="course" header="Course" style={{ width: '5%' }} />
                <Column sortable field="update" header="Update" style={{ width: '10%' }}/>
                <Column sortable field="created" header="Create" style={{ width: '10%' }}/>
            </DataTable>
        </div>
        }
        </>
    )
}
