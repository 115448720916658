import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { uiActions } from '../UI/ui';


export const getLeads = createAsyncThunk("leads/getLeads", async (thunkAPI) => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + process.env.REACT_APP_WATCHDOG_KEY;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  const env =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_WATCHDOG_DEV
      : process.env.REACT_APP_WATCHDOG_PROD;
  return await axios
    .get(env)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      thunkAPI.dispatch(
        uiActions.showNotification({
          severity: "error",
          summary: err.response.data.data.status,
          detail: err.response.data.code,
          life: 3000,
        })
      );
    });
});

const initialState = {
  leads: {
    status: null,
    data: {},
  },
};

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  extraReducers: {
    [getLeads.pending]: (state, action) => {
      state.leads.status = "loading";
    },
    [getLeads.fulfilled]: (state, action) => {
      state.leads.status = "success";
      state.leads.data = action.payload;
    },
    [getLeads.rejected]: (state, action) => {
      state.leads.status = "feiled";
    },
  },
});

export default leadsSlice;
