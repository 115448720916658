import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAllFiles = createAsyncThunk('files/getAllFiles', async (urls) => {
  const getFilesData = async (url) => {
    const domain = url.second_domain ? url.second_domain : url.main_domain;
    const { slug } = url;
    return await caches
      .open(`${domain}`)
      .then(async (Cache) => {
        return await Cache.match(`${domain}getAssets.php`)
          .then(async (res) => {
            return await res.json().then((data) => data);
          })
          .catch((err) => {
            return Cache.add(`${domain}getAssets.php`)
              .then(() => {
                return Cache.match(`${domain}getAssets.php`).then((res) => {
                  return res.json().then((data) => data);
                });
              })
              .catch((err) => {
                return [];
              });
          });
      })
      .catch((err) => {
        return [];
      });
  };

  const filterFiles = async (url) => {
    const data = await getFilesData(url);
    const files = data.filter((i) => typeof i === 'object');
    const addSlug = files.map((i) => ({ ...i, slug: url.slug }));
    return addSlug;
  };

  const getFilesLoop = () => {
    const withCoords = Promise.all(urls.map((url) => filterFiles(url)));
    return withCoords;
  };

  const filesFinal = getFilesLoop();
  return filesFinal;
});

export const getFiles = createAsyncThunk('files/getFiles', async (url) => {
  return await caches.open(`${url}`).then(function (Cache) {
    return Cache.match(`${url}getAssets.php`)
      .then((res) => {
        //univerisits cache files excist
        return res.json().then((data) => {
          return data;
        });
      })
      .catch((err) => {
        //univerisits cache files not excist
        return Cache.add(`${url}getAssets.php`).then(() => {
          return Cache.match(`${url}getAssets.php`).then((res) => {
            //univerisits cache files excist
            return res.json().then((data) => {
              return data;
            });
          });
        });
      });
  });
});

const initialState = {
  files: {
    status: null,
    data: {},
  },
  allFiles: {
    status: null,
    data: {},
  },
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  extraReducers: {
    [getFiles.pending]: (state, action) => {
      state.files.status = 'loading';
    },
    [getFiles.fulfilled]: (state, action) => {
      state.files.status = 'success';
      state.files.data = action.payload;
    },
    [getFiles.rejected]: (state, action) => {
      state.files.status = 'feiled';
    },
    [getAllFiles.pending]: (state, action) => {
      state.allFiles.status = 'loading';
    },
    [getAllFiles.fulfilled]: (state, action) => {
      state.allFiles.status = 'success';
      state.allFiles.data = action.payload;
    },
    [getAllFiles.rejected]: (state, action) => {
      state.allFiles.status = 'feiled';
    },
  },
});

export default filesSlice;
