import axiosApi from "../axiosApi";
import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import { uiActions } from '../UI/ui';

export const getUniversities = createAsyncThunk(
    "universities/getUniversities",
    async (thunkAPI) => {
        return await axiosApi.get('/wp-json/jwt-auth/v1/universities').then(
            (res) => res.data
        ).catch((err) => {
            console.log(err)
            thunkAPI.dispatch(uiActions.showNotification({
                severity: 'error',
                summary: err.response.data.data.status,
                detail: err.response.data.code,
                life: 3000
            }))
        });
    }
)

export const getUniversity = createAsyncThunk(
    "university/getUniversity",
    async (id, thunkAPI) => {
        return await axiosApi.get('/wp-json/jwt-auth/v1/university/' + id).then(
            (res) => res.data
        ).catch((err) => {
            thunkAPI.dispatch(uiActions.showNotification({
                severity: 'error',
                summary: err.response.data.data.status,
                detail: err.response.data.code,
                life: 3000
            }))
        });
    }
)

const initialState = { 
    universities: {
        status: null ,
        data: {} 
    }, 
    university: {
        status: null , 
        data: {} 
    }
}

const universitiesSlice = createSlice({
    name: 'universities',
    initialState,
    extraReducers: {
        [getUniversities.pending]: (state , action) => {
            state.universities.status = "loading"
        },
        [getUniversities.fulfilled]: (state, action) => {
            state.universities.status = "success"
            state.universities.data = action.payload
        },
        [getUniversities.rejected]: (state, action) => {
            state.universities.status = "feiled"
        },
        [getUniversity.pending]: (state , action) => {
            state.university.status = "loading"
        },
        [getUniversity.fulfilled]: (state, action) => {
            state.university.status = "success"
            state.university.data = action.payload
        },
        [getUniversity.rejected]: (state, action) => {
            state.university.status = "feiled"
        }
    }
})

export default universitiesSlice;
