import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import { uiActions } from '../UI/ui';
import axiosAuth from "../axiosAuth";

export const userLogin = createAsyncThunk(
    "users/userLogin",
    async (loginData , thunkAPI) => {
        return await axiosAuth.post('/wp-json/jwt-auth/v1/token', {
            username: loginData.username,
            password: loginData.password
        }).then(
            (res) => {
                localStorage.setItem('token' , res.data.token)
                localStorage.setItem('user' , JSON.stringify(res.data.profile))

                setTimeout(()=>{
                    thunkAPI.dispatch(authActions.handleLogout())
                },1000 * 60 * 60 * 9)

                return res.data
            }
        ).catch((err) => {
            thunkAPI.dispatch(uiActions.showNotification({
                severity: 'error',
                summary: err.response.data.data.status,
                detail: 'User unable to login',
                life: 3000
            }))
        });
    }
)


const initialState = { 
    user: {
        status: null ,
        isLogin: localStorage.getItem('user') ? true : false,
        data: localStorage.getItem('user')
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        handleLogout(state){
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            caches.keys().then(function(names) {
                for (let name of names)
                  caches.delete(name);
              });

            state.user.isLogin = false
            window.location.href = '/login'
        }
    },
    extraReducers: {
        [userLogin.pending]: (state , action) => {
            state.user.status = "loading"
        },
        [userLogin.fulfilled]: (state, action) => {
            state.user.status = "success"
            state.user.isLogin = true
            state.user.data = action.payload.profile

            window.location.href = '/'
        },
        [userLogin.rejected]: (state, action) => {
            state.user.status = "feiled"
        }
    }
})

export const authActions = userSlice.actions;

export default userSlice;