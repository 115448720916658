import CopyToClipboardComp from "../../ux/CopyToClipboardComp";


export default function DashInfo(props) {
  const university = props.data;
  return (
    <>
      <div className="card-box dash-uni-screenshot">
        <img
          src={university.screenshot_url ? university.screenshot_url : "/img/nouni.png"}
          className="img-thumbnail img-fluid"
          alt=""
        />
      </div>
      <div className="dash-uni-welcome">
        <h3 className="uni-name">
          <CopyToClipboardComp
            identifier="uni-name-copy"
            text={university.title}
            html={false}
            showIcon={false}
          />
          <span
            className="badge ms-2 text-uppercase"
            style={
              university.brand.brand_colors ? {backgroundColor: university.brand.brand_colors[0].brand_color} : {backgroundColor:"#09B261"}
            }
          >
            <CopyToClipboardComp
              identifier="uni-slug-copy"
              text={university.slug}
              html={false}
              showIcon={false}
            />
          </span>
        </h3>
        <ul className="uni-numbers">
          <li className="uni-number">
            <i className="dripicons-graduation"></i>
            {university.courses.length} Programs
          </li>
          <li className="uni-number">
            <i className="dripicons-device-desktop"></i>
            {props.pages} Pages
          </li>
          <li className="uni-number">
            <i className="dripicons-device-mobile"></i>
            {props.lps} Landing Pages
          </li>
        </ul>
        <div className="uni-courses">
          {university.courses && university.courses.map((course, i) => (
            <span
              className="badge bg-dark me-1"
              style={{ color: course.color }}
              key={i}
            >
              <CopyToClipboardComp
                identifier="uni-address"
                text={course.title}
                html={true}
                showIcon={false}
              />
            </span>
          ))}
        </div>
        <div className="uni-webmaster">
          <img
            src={university.user.avatar}
            alt="user-pic"
            className="rounded-circle avatar-md bx-shadow-lg"
            style={
              university.brand.brand_colors ? {border: '3px solid ' + university.brand.brand_colors[0].brand_color} : {border: '#09B261'}
            }
          />
          <div className="uni-webmaster-info">
            <h4>{university.user.nickname}</h4>
            <small className="uni-webmaster-update">
              Last update: <span>{university.update}</span>
            </small>
          </div>
        </div>
      </div>
    </>
  );
}
