import { useDispatch } from 'react-redux'
import { userLogin } from "../../store/auth/auth";
import { useFormik } from 'formik';

export default function Login() {
    
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.username) {
                errors.username = 'Username is required.';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            const loginData = {
                username: data.username,
                password: data.password
            }

            dispatch(userLogin(loginData));
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    
    return (
        <>
        <div className="account-pages w-100 mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">

                            <div className="card-body p-4">
                                
                                <div className="text-center mb-4">
                                    <a href="index.html">
                                        <span><img src="../img/logo-light.svg" alt="" height="28"/></span>
                                    </a>
                                </div>

                                <form onSubmit={formik.handleSubmit} action="#" className="pt-2">

                                    <div className="form-group mb-3">
                                        <label htmlFor="username" className={isFormFieldValid("username") && 'p-error'}>Username</label>
                                        <input name="username" type="text" id="username" formNoValidate placeholder="Enter your username" value={formik.values.username} onChange={formik.handleChange} className={isFormFieldValid("username") ? 'form-control p-invalid' : 'form-control'}/>
                                        {getFormErrorMessage('username')}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="password" className={isFormFieldValid("password") && 'p-error'} >Password</label>
                                        <input name="password" type="password" formNoValidate id="password" placeholder="Enter your password" value={formik.values.password} onChange={formik.handleChange} className={isFormFieldValid("password") ? 'form-control p-invalid' : 'form-control'}/>
                                        {getFormErrorMessage('password')}
                                    </div>

                                    <div className="form-group mb-0 text-center">
                                        <button className="btn btn-success btn-block" type="submit"> Log In </button>
                                    </div>

                                </form>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        </>
    )
}
