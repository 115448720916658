import CopyToClipboardComp from "../../ux/CopyToClipboardComp";

export default function DashData(props) {
  const university = props.data;
  return (
    <>
      <h4 class="header-title mb-3">University Info</h4>
      <div className="uni-data-info">
        <div className="infoItem">
          <div className="infoItem-name">Adderss</div>
          <div className="infoItem-data">
            <CopyToClipboardComp
              identifier="uni-address"
              text={university.address}
              html={true}
              showIcon={false}
            />
          </div>
        </div>
        <div className="infoItem">
          <div className="infoItem-name">Phones</div>
          <div className="infoItem-data">
            <CopyToClipboardComp
              identifier="uni-phone"
              text={university.phone_number}
              html={false}
              showIcon={false}
            />
          </div>
        </div>
        <div className="infoItem">
          <div className="infoItem-name">eMail</div>
          <div className="infoItem-data">
            <CopyToClipboardComp
              identifier="uni-email"
              text={university.email}
              html={false}
              showIcon={false}
            />
          </div>
        </div>
        <div className="infoItem">
          <div className="infoItem-name">Privacy Policy</div>
          <div className="infoItem-data">
            <a target="_blank" rel="noreferrer" href={university.privacy}>
              <CopyToClipboardComp
                identifier="uni-privacy"
                text={university.privacy}
                html={false}
                showIcon={false}
              />
            </a>
          </div>
        </div>
        <div className="infoItem">
          <div className="infoItem-name">Social Pages</div>
          <div className="infoItem-data social-links">
            {university.social.length && university.social.map((page, i) => (
              <a key={i} target="_blank" rel="noreferrer" href={page.social_link_page}>
                <CopyToClipboardComp
                  identifier={"uni-social-"+page.social_link_type}
                  text={page.social_link_page}
                  html={false}
                  showIcon={`mdi mdi-${page.social_link_type}`}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="infoItem">
          <div className="infoItem-name">Main Domain</div>
          <div className="infoItem-data">
            <a target="_blank" rel="noreferrer" href={university.main_domain}>
              <CopyToClipboardComp
                identifier="uni-domain"
                text={university.main_domain}
                html={false}
                showIcon={false}
              />
            </a>
          </div>
        </div>
        {university.second_domain && 
        <div className="infoItem">
        <div className="infoItem-name">Second Domain</div>
        <div className="infoItem-data">
          <a target="_blank" rel="noreferrer" href={university.second_domain}>
            <CopyToClipboardComp
              identifier="uni-sec-domain"
              text={university.second_domain}
              html={false}
              showIcon={false}
            />
          </a>
        </div>
      </div>
        
        }
        <div className="infoItem">
          <div className="infoItem-name">Dev Domain</div>
          <div className="infoItem-data">
            <a target="_blank" rel="noreferrer" href={university.staging_domain}>
              <CopyToClipboardComp
                identifier="uni-dev-domain"
                text={university.staging_domain}
                html={false}
                showIcon={false}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
