import axiosApi from "../axiosApi";
import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';


export const getUsers = createAsyncThunk(
    "users/getUsers",
    async () => {
        return await axiosApi.get('/wp-json/jwt-auth/v1/users').then(
            (res) => res.data
        );
    }
)

const initialState = { 
    users: {
        status: null ,
        data: {} 
    }
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    extraReducers: {
        [getUsers.pending]: (state , action) => {
            state.users.status = "loading"
        },
        [getUsers.fulfilled]: (state, action) => {
            state.users.status = "success"
            state.users.data = action.payload
        },
        [getUsers.rejected]: (state, action) => {
            state.users.status = "feiled"
        }
    }
})

export default usersSlice;
