import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getAllFiles } from '../../store/files/filesThunk';
import _ from 'lodash';

export default function Catalogs() {
  const dispatch = useDispatch();

  const files = useSelector((state) => state.files.allFiles.data);
  const filesStatus = useSelector((state) => state.files.allFiles.status);
  const universities = useSelector((state) => state.universities.universities.data);
  const unis_status = useSelector((state) => state.universities.universities.status);
  const filesFlat = _.flatten(files);

  const universitiesMainDomains = _.map(universities, (i) => {
    return _.pick(i, 'main_domain', 'second_domain', 'slug');
  });

  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (unis_status === 'success') {
      dispatch(getAllFiles(universitiesMainDomains));
    }
  }, [unis_status, dispatch]);

  useEffect(() => {
    const addData = () => {
      const newFiles = filesFlat.map((file) => {
        return {
          ...file,
          type: file.source_url.indexOf('catalogs') > -1 ? 'catalogs' : 'ebooks',
          course: getFileCourse(file.title.rendered),
        };
      });
      return newFiles;
    };

    if (filesStatus === 'success') {
      setData(addData());
    }
  }, [filesStatus]);

  const reset = () => {
    setGlobalFilter('');
    dt.current.reset();
  };

  const getFileCourse = (title) => {
    const course = title.toLowerCase();
    if (course.match(/(cs|cyber|security)/)) {
      return 'CS';
    } else if (course.match(/(dm|digital|marketing)/)) {
      return 'DM';
    } else if (course.match(/(fs|full|stack)/)) {
      return 'DS';
    } else if (course.match(/(ux|ui)/)) {
      return 'UX';
    } else if (course.match(/(ds|data|science)/)) {
      return 'DSA';
    } else {
      return 'GN';
    }
  };

  const header = (
    <div className="table-header">
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Keyword Search" />
        </span>
      </div>
    </div>
  );

  const slugBodyTemplate = (rowData) => {
    return <span className="badge bg-dark text-light text-capitalize">{rowData.slug}</span>;
  };

  const urlBodyTemplate = (rowData) => {
    return (
      <a href={rowData.source_url} target="_blank" rel="noreferrer">
        {rowData.source_url}
      </a>
    );
  };

  const typeBodyTemplate = (rowData) => {
    if (rowData.type === 'catalogs') {
      return <span className="text-warning me-2">{rowData.type}</span>;
    } else {
      return <span className="text-info me-2">{rowData.type}</span>;
    }
  };

  const courseBodyTemplate = (rowData) => {
    const course = rowData.course;
    if (course === 'CS') {
      return (
        <span className="badge bg-dark me-1" style={{ color: '#35b8e0' }}>
          {course}
        </span>
      );
    } else if (course === 'DM') {
      return (
        <span className="badge bg-dark me-1" style={{ color: '#dd9933' }}>
          {course}
        </span>
      );
    } else if (course === 'DS') {
      return (
        <span className="badge bg-dark me-1" style={{ color: '#8224e3' }}>
          {course}
        </span>
      );
    } else if (course === 'UX') {
      return (
        <span className="badge bg-dark me-1" style={{ color: '#f56cb3' }}>
          {course}
        </span>
      );
    } else if (course === 'DSA') {
      return (
        <span className="badge bg-dark me-1" style={{ color: '#00a38d' }}>
          {course}
        </span>
      );
    } else {
      return (
        <span className="badge bg-dark me-1" style={{ color: '#35b8e0' }}>
          {course}
        </span>
      );
    }
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">SUM</a>
                      </li>
                      <li className="breadcrumb-item active">Catalogs/Ebooks</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Catalogs/Ebooks</h4>
                </div>
              </div>
            </div>
            {filesStatus === 'loading' && <ProgressSpinner />}
            {filesStatus === 'success' && (
              <div className="row">
                <div className="col-12">
                  <div className="card-box">
                    <div className="table-responsive datatable-filter-demo">
                      {
                        <DataTable ref={dt} value={data} header={header} dataKey="id" className="p-datatable-universities" globalFilter={globalFilter} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} removableSort stripedRows resizableColumns columnResizeMode="fit" emptyMessage="No Catalog/Ebooks found.">
                          <Column field="title.rendered" header="Title" filter filterPlaceholder="Search by Title" sortable />
                          <Column field="slug" filter filterPlaceholder="Search by Slug" body={slugBodyTemplate} header="University Slug" sortable />
                          <Column field="source_url" filter filterPlaceholder="Search by Url" body={urlBodyTemplate} header="URL" sortable />
                          <Column field="type" filter filterPlaceholder="Search by Type" body={typeBodyTemplate} header="Type" sortable />
                          <Column field="course" filter filterPlaceholder="Search by course" body={courseBodyTemplate} header="Course" sortable />
                        </DataTable>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
