import { useState, useRef , useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import _ from 'lodash';
import { getUrls } from '../../store/urls/urlsThunk';

export default function SearchTable() {
    const universities = useSelector(state => state.universities.universities.data)
    const status = useSelector(state => state.urls.urls.status)
    const unis_status = useSelector(state => state.universities.universities.status)
    const urls = useSelector(state => state.urls.urls.data)

    const urlsFlat = _.flattenDeep(urls)

    let universitiesMainDomains = _.map(universities, i => _.pick(i , 'main_domain', 'second_domain'));
    const dispatch = useDispatch();

    useEffect(() => {
        if(unis_status === "success") {
            dispatch(getUrls(universitiesMainDomains));
        }
    }, [ unis_status , dispatch]);

    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [filters1, setFilters1] = useState(null);

    const reset = () => {
        setGlobalFilter('');
        dt.current.reset();
    }

    const header = (
        <div className="table-header">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const statuses = ['publish' , 'old-lp' , 'development']

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const statusItemTemplate = (option) => {
        if (option === "publish") {
            return <span className="badge bg-success text-capitalize">{option}</span>;
        } if (option === "old-lp") {
            return <span className="badge bg-info text-capitalize">{option}</span>;
        } else {
            return <span className="badge bg-danger text-capitalize">{option}</span>;
        }
    }


    const statusBodyTemplate = (rowData) => {

        if (rowData.status === "publish") {
            return <span className="badge bg-success text-capitalize">{rowData.status}</span>;
        } if (rowData.status === "old-lp") {
            return <span className="badge bg-info text-capitalize">{rowData.status}</span>;
        } else {
            return <span className="badge bg-danger text-capitalize">{rowData.status}</span>;
        }

    }

    const courses = ['cs' , 'dm' , 'ux' , 'ds' , 'fs']

    const courseFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={courses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={courseItemTemplate} placeholder="Select a course" className="p-column-filter" showClear />;
    }

    const courseItemTemplate = (option) => {
        return <span className="badge bg-info">{option}</span>;
    }


    const urlBodyTemplate = (rowData) => {
        return <a href={rowData.link} target="_blank" rel="noreferrer">{rowData.link}</a>;
    }


    const typeBodyTemplate = (rowData) => {
        let str = rowData.template;
        let newStr = str.replace('views/lp', '').replace('.blade.php','').replace('-',' ');
        return <span className="badge bg-dark text-light text-capitalize">{newStr}</span>;
    }

    const handleClearCache = (e) => {
        e.preventDefault()
    
        caches.keys().then(function(names) {
            for (let name of names)
              caches.delete(name);
          });
          window.location.reload(false);
      }


    return (
        <>
        {status === "loading" && <ProgressSpinner />}
        {status === "success" &&
        <div className="row">
            <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dropdown float-end">
                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-dots-vertical"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a onClick={e=>handleClearCache(e)} className="dropdown-item"><i class="mdi mdi-broom me-2"></i>Clear LPs Cache</a>
                            </div>
                        </div>
                    <h4 className="mb-3">Landing Pages Search Tool</h4>
                    <p class="sub-header">You can use the <span class="pi pi-filter-icon pi-filter"></span> icon to filter the results</p>
                    <div className="table-responsive datatable-filter-demo">  
                        <DataTable ref={dt} value={urlsFlat} paginator rows={20}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
                            header={header} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} lps" className="p-datatable-universities"
                            dataKey="id" filters={filters1} filterDisplay="menu"
                            globalFilter={globalFilter} removableSort stripedRows resizableColumns columnResizeMode="fit" emptyMessage="No Lps found.">
                            <Column sortable field="title" filter filterPlaceholder="Search by title" header="Title" />
                            <Column sortable field="template" body={typeBodyTemplate} filter filterPlaceholder="Search by template" header="Template"  />
                            <Column sortable field="link" filter filterPlaceholder="Search by link" body={urlBodyTemplate} header="URL" />
                            <Column sortable field="status" filter filterElement={statusFilterTemplate} body={statusBodyTemplate} header="Status"  />
                            <Column sortable field="course" filter filterElement={courseFilterTemplate} header="Course" />
                            <Column sortable field="update" header="Update" />
                            <Column sortable field="created" header="Create" />
                        </DataTable>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
    )
}
