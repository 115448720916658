import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import _ from "lodash";




export default function UniversityCard(props) {
  const university = props.data;

  const userjson = useSelector((state) => state.user.user.data);


  let user = "";

  if (_.isObject(userjson)) {
    user = userjson;
  } else {
    user = JSON.parse(userjson);
  }

  const getInitials = (name) => {
    var names = name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const universityColor = university.brand.brand_colors ? university.brand.brand_colors[0].brand_color : '#09B261'

  return (
    <div className="university-card d-flex align-items-stretch col mb-3" >
      <ReactTooltip />
        <div className="university-body" style={{borderBottom: '3px solid '+ universityColor }}>
        <Link to={"/universities/" + university.id}>
          <div className="university-img">
            <img className="img-fluid" src={university.screenshot_url ? university.screenshot_url : "/img/nouni.png"} alt="" />
            <div className="university-user" id="university-user" data-tip={university.user.nickname}>
            {university.user.avatar ? (
              <img
                src={university.user.avatar}
                alt="user-pic"
                className="university-avatar rounded-circle avatar-lg bx-shadow-lg"
                style={{border: '3px solid '+ universityColor }}
              />
            ) : (
              <span className="rounded-circle avatar-lg bx-shadow-lg avatarNone">
                {getInitials(university.user.nickname)}
              </span>
            )}
          </div>
          </div>
        </Link>
          
          <h4 className="university-name"><img className="university-favicon" src={university.favicon} alt="" />{university.title}</h4>
          <small className="university-update text-muted">
            Last update: <span>{university.update}</span>
          </small>
          <div className="university-courses mt-2 mb-4">
            {university.courses && university.courses.map((course, i) => (
              <span className="badge bg-dark me-1" style={{color: course.color}} dangerouslySetInnerHTML={{
                __html: course.title
              }}></span>
            ))}
          </div>
          <div className="university-btns">
          {!_.includes(user.user_roles, "subscriber") && (
            <Link to={"/universities/" + university.id} className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-file-document"></i> University Page</Link>
          )}
            <a href={university.main_domain} target="_blank" rel="noreferrer" className="btn btn-outline-primary waves-effect waves-light width-md"><i className="mdi mdi-web"></i> Website</a>
          </div>
        </div>
    </div>
  );
}
