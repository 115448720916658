import CopyToClipboardComp from "../../ux/CopyToClipboardComp";

export default function DashBrand(props) {
  const university = props.data;
  return (
    <>
      <h4 className="header-title mb-3">Brand Essets</h4>
      <div className="uni-brand">
        <div className="uni-brand-logos">
          <h5>Logos</h5>
          <div className="logos">
            <img
              src={university.brand.brand_logo}
              className="logo-main"
              alt=""
            />
            <img src={university.favicon} className="logo-favicon" alt="" />
          </div>
        </div>
        <div className="uni-brand-colors">
          <h5>Colors</h5>
          <ul className="colors">
            {university.brand.brand_colors.length &&
              university.brand.brand_colors.map((color, i) => (
                <li
                  key={i}
                  className="color"
                  style={{ backgroundColor: color.brand_color }}
                >
                  {color.brand_color_primary && (
                    <div className="is-primary">
                      <i className="mdi mdi-star-box"></i>
                    </div>
                  )}
                  <div className="color-name">
                    <CopyToClipboardComp
                      identifier={"uni-color-" + color.brand_color}
                      text={color.brand_color}
                      html={false}
                      showIcon={false}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="uni-brand-fonts">
          <h5>Fonts</h5>
          <ul className="fonts">
            {university.brand.brand_fonts.length &&
              university.brand.brand_fonts.map((font, i) => (
                <li key={i} className="font mb-4">
                  <div className="font-type">{font.font_type}</div>
                  <div className="font-name">
                    {font.font_name}
                    {font.font_link && (
                      <a target="_blank" rel="noreferrer" href={font.font_link_url}>
                        <i className="mdi mdi-link"></i>
                      </a>
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="uni-brand-folders">
          <h5>Folders</h5>
          <ul className="folders">
            {university.brand.brand_links.length &&
              university.brand.brand_links.map((folder, i) => (
                <li key={i} className="folder mb-4">
                  <div className="folder-type">
                    <i className="dripicons-folder"></i>
                    {folder.link_type}
                  </div>
                  <div className="folder-name">
                    <CopyToClipboardComp
                      identifier={"uni-folder-" + folder.link_type}
                      text={folder.path}
                      html={false}
                      showIcon={false}
                    />
                  </div>
                </li>
              ))}

            <li className="folder folder-dam mt-4">
              <i className="dripicons-device-desktop"></i>
              DAM
              <a
                href={university.brand.dam}
                className="btn btn-info waves-effect waves-light width-md d-block mt-2 dam-link"
              >
                University Portal
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
