import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFiles } from '../../../store/files/filesThunk';

export default function DashFiles(props) {
  const university = props.data;
  const dispatch = useDispatch();
  const files = useSelector((state) => state.files.files.data);
  const filesStatus = useSelector((state) => state.files.files.status);

  const search = {
    CS: /(cs|cyber|security)/i,
    DM: /(dm|digital|marketing)/i,
    SD: /(sd|fs|full|stack|software|engineer)/i,
    DSA: /(dsa|data|science)/i,
    UX: /(ux)/i,
  };

  const color = {
    CS: '#35b8e0',
    DM: '#dd9933',
    SD: '#8224e3',
    DSA: '#00a38d',
    UX: '#f56cb3',
  };

  const handelCourseBadge = (course) => {
    let title = (
      <span className="badge bg-dark me-1" style={{ color: '#35b8e0' }}>
        GN
      </span>
    );
    Object.keys(search).map((key) => {
      if (search[key].test(course) === true) {
        title = (
          <span className="badge bg-dark me-1" style={{ color: color[key] }}>
            {key}
          </span>
        );
      }
    });

    return title;
  };

  const handelUrl = (url, title) => {
    if (title.includes('Catalog')) {
      const newURL = university.main_domain + 'catalogs/' + url.match(/[^\/]+$/)[0];
      return (
        <a target="_blank" rel="noreferrer" href={newURL}>
          {newURL}
        </a>
      );
    } else if (title.includes('Ebook')) {
      const newURL = university.main_domain + 'ebooks/' + url.match(/[^\/]+$/)[0];
      return (
        <a target="_blank" rel="noreferrer" href={newURL}>
          {newURL}
        </a>
      );
    } else {
      return (
        <a target="_blank" rel="noreferrer" href={url}>
          {url}
        </a>
      );
    }
  };

  const handelIcon = (title) => {
    if (title.includes('Catalog')) {
      return <i className="display-1 ti-agenda"></i>;
    } else if (title.includes('Ebook')) {
      return <i className="display-1 ti-book"></i>;
    } else {
      return <i className="display-1 ti-file"></i>;
    }
  };

  const handelSpan = (surl) => {
    if (surl.includes('catalogs')) {
      return (
        <small className="text-warning me-2">
          <b>Catalog</b>
        </small>
      );
    } else if (surl.includes('ebooks')) {
      return (
        <small className="text-info me-2">
          <b>Ebook</b>
        </small>
      );
    } else {
      return (
        <small className="text-primary me-2">
          <b>General PDF</b>
        </small>
      );
    }
  };

  useEffect(() => {
    dispatch(getFiles(university.main_domain));
  }, [dispatch]);

  return (
    <>
      {filesStatus === 'success' && (
        <>
          <h4 className="header-title mb-3">Files Links</h4>
          <div className="files d-flex flex-row flex-nowrap overflow-auto">
            {files &&
              files.map((file, i) => (
                <div key={i} className="card col-xl-4 mx-1">
                  <div className="card-body widget-user">
                    <div className="d-flex align-items-center">
                      <div className="me-3">{handelIcon(file.title.rendered)}</div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="mt-0 mb-1">{file.title.rendered}</h5>
                        <p className="text-muted mb-2 font-13 text-truncate">{handelUrl(file.source_url, file.title.rendered)}</p>
                        {handelSpan(file.source_url)}
                        {handelCourseBadge(file.title.rendered)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
}
