import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        view: "thumb",
        notification: null,
        theme: localStorage.getItem('theme') && localStorage.getItem('theme') === 'dark' ? localStorage.getItem('theme') : 'light'
    },
    reducers: {
        updateViewType(state, action){
            state.view = action.payload
        }, 
        showNotification(state, action){
            state.notification = {
                severity: action.payload.severity,
                summary: action.payload.summary,
                detail: action.payload.detail,
                life: action.payload.life
            }
        },
        setTheme(state , action){
            state.theme = action.payload
            localStorage.setItem("theme" , action.payload)
        }
    }
})

export const uiActions = uiSlice.actions;

export default uiSlice;
