import TopNav from "../../components/layout/TopNav";

export default function MainLayout(props) {
    const date = new Date().getFullYear()
  return (
    <>
      <div id="wrapper">
        {props.auth && <TopNav />}
        <div className="mb-5">
        {props.children}
        </div>
        <footer className="footer mt-5">
          <div className="container-fluid">
            <div className="row mb-0">
              <div className="col-md-4">
                {date} ©
                SUM - Studio Universitis Managment by <a href="https://thrivedx.com/">Thrivedx Studio</a>
              </div>
              <div className="col-md-4">
                <div className="text-md-center d-none d-sm-block">
                  V1.6
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-md-end footer-links d-none d-sm-block">
                  <a href="/releases">Releases</a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
