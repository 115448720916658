import BrandList from "../brand/BrandList";

export default function Brands() {
  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">SUM</a>
                    </li>
                    <li className="breadcrumb-item active">Brands</li>
                  </ol>
                </div>
                <h4 className="page-title">Brands</h4>
              </div>
            </div>
          </div>
          <BrandList />
        </div>
      </div>
    </div>
  );
}
