import React from 'react'

export default function Notfound() {
    return (
        <div className="account-pages w-100 mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card text-center">
                            <div className="card-body p-4">
                                <div className="mb-4">
                                    <a href="index.html">
                                        <span><img src="../img/logo-light.svg" alt="" height="28"/></span>
                                    </a>
                                </div>
                                <h1 className="text-error">404</h1>
                                <h4 className="text-uppercase text-danger mt-3">Page not found</h4>
                                <p className="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry... it
                                        happens to the best of us. Here's a
                                        little tip that might help you get back on track.</p>

                                <a className="btn btn-success mt-3" href="index.html"><i className="mdi mdi-home mr-1"></i> Return Home</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
