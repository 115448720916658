import axiosApi from "../axiosApi";
import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import { uiActions } from '../UI/ui';

export const getReleases = createAsyncThunk(
    "releases/getReleases",
    async (thunkAPI) => {
        return await axiosApi.get('/wp-json/wp/v2/releases').then(
            (res) => res.data
        ).catch((err) => {
            console.log(err)
            thunkAPI.dispatch(uiActions.showNotification({
                severity: 'error',
                summary: err.response.data.data.status,
                detail: err.response.data.code,
                life: 3000
            }))
        });
    }
)

const initialState = { 
    releases: {
        status: null ,
        data: {} 
    }
}

const releasesSlice = createSlice({
    name: 'releases',
    initialState,
    extraReducers: {
        [getReleases.pending]: (state , action) => {
            state.releases.status = "loading"
        },
        [getReleases.fulfilled]: (state, action) => {
            state.releases.status = "success"
            state.releases.data = action.payload
        },
        [getReleases.rejected]: (state, action) => {
            state.releases.status = "feiled"
        }
    }
})

export default releasesSlice;
