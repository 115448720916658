import { Route , Redirect } from "react-router-dom";
import { useSelector } from "react-redux";


export default function AuthRoute({ component: Component, ...rest }) {
  const login = localStorage.getItem("token");
  const isLogin = useSelector((state) => state.user.user.isLogin);
  return (
    <Route
      {...rest}
      render={(props) => {
        if(login && isLogin){
            return <Component {...props} />;
        }else{
            return <Redirect to={
                {
                    pathname: "/login",
                    state: {
                        from: props.location
                    }
                }
            } />
        }
      }}
    />
  );
}
