import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUniversity } from '../../store/universities/universitiesThunk';
import { getPages } from '../../store/universitiesPages/pagesThunk';
import { getLps } from '../../store/lps/lpsThunk';
import { getFreemium } from '../../store/freemium/freemiumThunk';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import _ from 'lodash';

import DashPages from './universityDash/DashPages';
import DashLps from './universityDash/DashLps';
import DashInfo from './universityDash/DashInfo';
import DashData from './universityDash/DashData';
import DashBrand from './universityDash/DashBrand';
import DashNotes from './universityDash/DashNotes';
import DashFiles from './universityDash/DashFiles';
import DashFreemium from './universityDash/DashFreemium';

export default function University() {
  let { id } = useParams();

  const dispatch = useDispatch();

  const university = useSelector((state) => state.universities.university.data)[0];
  const status = useSelector((state) => state.universities.university.status);
  const pages = useSelector((state) => state.pages.pages.data);
  const lps = useSelector((state) => state.lps.lps.data);
  const freemium = useSelector((state) => state.freemium.freemium.data);
  const files = useSelector((state) => state.files.files.data);

  const handleClearCache = (e) => {
    e.preventDefault();

    const cacheType = e.target.getAttribute('data-cache');
    caches.delete(cacheType);
    window.location.reload(false);
  };

  useEffect(() => {
    dispatch(getUniversity(id)).then((res) => {
      dispatch(getPages(res.payload[0].main_domain));
      const lpUrl = res.payload[0].second_domain ? res.payload[0].second_domain : res.payload[0].main_domain;
      dispatch(getLps(lpUrl));
      dispatch(getFreemium(res.payload[0].freemium_domain));
    });
  }, [id, dispatch]);

  const menu = useRef(null);

  const heandelItems = () => {
    if (status === 'success') {
      const menuItems = ['main', 'info', 'brand', 'files', 'notes', 'lps', 'pages', 'freemium'];
      const menuItemsFilter = _.remove(menuItems, function (item) {
        const items = [];
        if (!university.notes) {
          items.push('notes');
        }
        if (_.isEmpty(files)) {
          items.push('files');
        }
        return _.indexOf(items, item) !== -1;
      });

      const finelItems = [];

      menuItems.map((item) =>
        finelItems.push({
          label: item.charAt(0).toUpperCase() + item.slice(1),
          icon: 'pi pi-bookmark',
          command: (e) => {
            window.location.hash = '#' + item;
          },
        })
      );
      return finelItems;
    }
  };

  const items = [
    {
      label: 'Go To',
      items: heandelItems(),
    },
  ];

  return (
    <>
      {status === 'success' && (
        <div className="content-page position-relative" id="university-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="/">SUM</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/universities">Universities</a>
                        </li>
                        <li className="breadcrumb-item active">{university.title}</li>
                      </ol>
                    </div>
                    <h4 className="page-title">
                      <span className="me-2">
                        <img width="20" src={university.favicon} alt="" />
                      </span>
                      {university.title}
                      <span
                        className="badge ms-2 text-uppercase"
                        style={
                          university.brand.brand_colors
                            ? {
                                backgroundColor: +university.brand.brand_colors[0].brand_color,
                              }
                            : { backgroundColor: '#09B261' }
                        }
                      >
                        {university.slug}
                      </span>
                      <a href={university.main_domain} target="_blank" rel="noreferrer" className="ms-3">
                        <i className="mdi mdi-web"></i>
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-7" id="main">
                  <div className="card">
                    <div className="card-body dash-uni">
                      <DashInfo data={university} pages={pages && pages.length} lps={lps && lps.length} />
                    </div>
                  </div>
                </div>
                <div className="col col-5" id="info">
                  <div className="card">
                    <div className="card-body">
                      <DashData data={university} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12" id="brand">
                  <div className="card">
                    <div className="card-body">
                      <DashBrand data={university} />
                    </div>
                  </div>
                </div>
              </div>
              {files && (
                <div className="row">
                  <div className="col-12" id="files">
                    <DashFiles data={university} />
                  </div>
                </div>
              )}
              {university.notes && (
                <div className="row">
                  <div className="col-12" id="notes">
                    <div className="card">
                      <div className="card-body">
                        <DashNotes data={university} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12" id="lps">
                  <div className="card">
                    <div className="card-body">
                      <div className="dropdown float-end">
                        <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="mdi mdi-dots-vertical"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a onClick={(e) => handleClearCache(e)} data-cache={university.main_domain} className="dropdown-item">
                            <i class="mdi mdi-broom me-2"></i>Clear LPs Cache
                          </a>
                        </div>
                      </div>
                      <DashLps data={lps} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12" id="pages">
                  <div className="card">
                    <div className="card-body">
                      <div className="dropdown float-end">
                        <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="mdi mdi-dots-vertical"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a onClick={(e) => handleClearCache(e)} data-cache={university.main_domain} className="dropdown-item">
                            <i class="mdi mdi-broom me-2"></i>Clear Pages Cache
                          </a>
                        </div>
                      </div>
                      <DashPages data={pages} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12" id="pages">
                  <div className="card">
                    <div className="card-body">
                      <div className="dropdown float-end">
                        <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="mdi mdi-dots-vertical"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a onClick={(e) => handleClearCache(e)} data-cache={university.freemium_domain} className="dropdown-item">
                            <i class="mdi mdi-broom me-2"></i>Clear Pages Cache
                          </a>
                        </div>
                      </div>
                      <DashFreemium data={freemium} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Menu model={items} popup ref={menu} id="popup_menu" />
          <Button className="p-button-rounded p-button-lg position-fixed menu-btn" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
        </div>
      )}
    </>
  );
}
