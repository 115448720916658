import { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import CopyToClipboardComp from "../ux/CopyToClipboardComp";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


export default function BrandList() {

    const universities = useSelector(state => state.universities.universities.data)
    const status = useSelector(state => state.universities.universities.status)

    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState('');
    
    const reset = () => {
        setGlobalFilter('');
        dt.current.reset();
    }


    const header = (
        <div className="table-header">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const nameBodyTemplate = (rowData) => {
        return (
            <>
            <img alt="favicon" style={{ marginRight:  "1rem" }} src={rowData.favicon} width={30} />
            <span className="text-uppercase">{rowData.slug}</span>
            </>
        );
    }

    const colorsBodyTemplate = (rowData) => {
        return (
            <>
          <ul className="colors-table">
            {rowData.brand.brand_colors.length && rowData.brand.brand_colors.map((color, i) => {
              if (color.brand_color_primary) {
                return (
                  <li
                    key={i}
                    className="color"
                    style={{ backgroundColor: color.brand_color }}
                  >
                    <div className="is-primary">
                      <i className="mdi mdi-star-box"></i>
                    </div>
                    <div className="color-name">
                      <CopyToClipboardComp
                        identifier={"uni-color-table" + color.brand_color}
                        text={color.brand_color}
                        html={false}
                        showIcon={"mdi mdi-content-copy"}
                      />
                    </div>
                  </li>
                );
              }
              return;
            })}
          </ul>
          <ul className="colors-table mt-2">
          {rowData.brand.brand_colors.length && rowData.brand.brand_colors.map((color, i) => {
            if (!color.brand_color_primary) {
              return (
                <li
                  key={i}
                  className="color"
                  style={{ backgroundColor: color.brand_color }}
                >
                  <div className="color-name">
                    <CopyToClipboardComp
                      identifier={"uni-color-table" + color.brand_color}
                      text={color.brand_color}
                      html={false}
                      showIcon={"mdi mdi-content-copy"}
                    />
                  </div>
                </li>
              );
            }
            return;
          })}
        </ul>
        </>
        );
    }

    const actionsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Actions</span>
                <Link data-toggle="tooltip" data-placement="top" title="" data-original-title="University Page" to={"/universities/" + rowData.id} className="btn btn-primary waves-effect waves-light me-1"><i class="mdi mdi-file-document"></i></Link>
            </>
        );
    }

    const foldersBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Folders</span>
                <ul className="folders-table">
                {rowData.brand.brand_links.length && rowData.brand.brand_links.map((folder, i) => (
                    <li key={i} className="folder">
                        <CopyToClipboardComp
                            identifier={"uni-folder-table" + folder.link_type}
                            text={folder.path}
                            html={false}
                            showIcon={"dripicons-folder"}
                        />
                        {folder.link_type}
                    </li>
                ))}
                </ul>
            </>
        );
    }

    const fontsBodyTemplate = (rowData) => {
        return (
          <>
            <span className="p-column-title">Fonts</span>
            <ul className="folders-table">
              {rowData.brand.brand_fonts.length && rowData.brand.brand_fonts.map((font, i) => (
                <li key={i} className="folder">
                  <span className="font-type">{font.font_type}</span>{font.font_name}
                  {font.font_link && (
                    <a target="_blank" rel="noreferrer" href={font.font_link_url}>
                      <i className="mdi mdi-link"></i>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </>
        );
    }
    
    return (
        <>
        {status === "success" && 
        <div className="row">
            <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive datatable-filter-demo">  
                        {<DataTable ref={dt} value={universities}
                            header={header} className="p-datatable-universities"
                            globalFilter={globalFilter} removableSort stripedRows emptyMessage="No Universities found.">
                            <Column field="slug" header="University" body={nameBodyTemplate} sortable style={{width:'10%'}}/>                            
                            <Column field="brand.brand_colors.brand_color" header="Colors" body={colorsBodyTemplate} style={{width:'40%'}}/>  
                            <Column field="brand.brand_links.link_type" header="Folders" body={foldersBodyTemplate} style={{width:'15%'}}/>  
                            <Column field="brand.brand_fonts.font_type" header="Fonts" body={fontsBodyTemplate} style={{width:'20%'}}/>  
                            <Column body={actionsBodyTemplate} header="Go To" style={{width:'10%'}}/>
                          
                        </DataTable>}

                    </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}
