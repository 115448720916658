import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';

export const getPages = createAsyncThunk(
    "pages/getPages",
    async (url , thunkAPI) => {

        return await caches.open(`${url}`).then(function (Cache) {
    
            return Cache.match(`${url}wp-json/data/v1/pages`).then(res=>{
              //univerisits cache lps excist
              return res.json().then(data => {
                return data
              })
        
            }).catch(err=>{
              //univerisits cache lps not excist
              return Cache.add(`${url}wp-json/data/v1/pages`).then(() => {
                return Cache.match(`${url}wp-json/data/v1/pages`).then(res=>{
                    //univerisits cache lps excist
                    return res.json().then(data => {
                      return data
                    })
              
                  })
              });
            })
            
          });


        // return await axiosUniweb.get(`${url}wp-json/data/v1/pages`).then(
        //     (res) => {
        //         thunkAPI.dispatch(uiActions.showNotification({
        //             severity: 'success',
        //             summary: 'Success',
        //             detail: 'Getting Universities Pages are done',
        //             life: 3000
        //         }))
        //         caches.open(`${url}wp-json/data/v1/pages`).then(function(Cache) {
        //             return Cache.add(`${url}wp-json/data/v1/pages`);
        //         })
        //         return res.data
        //     }
        // ).catch((err) => {
        //     thunkAPI.dispatch(uiActions.showNotification({
        //         severity: 'error',
        //         summary: err.response.data.data.status,
        //         detail: 'Pages request returned: ' + err.response.data.message,
        //         life: 3000
        //     }))
        // });
    }
)
                


const initialState = { 
    pages: {
        status: null ,
        data: {} 
    }
}

const pagesSlice = createSlice({
    name: 'pages',
    initialState,
    extraReducers: {
        [getPages.pending]: (state , action) => {
            state.pages.status = "loading"
        },
        [getPages.fulfilled]: (state, action) => {
            state.pages.status = "success"
            state.pages.data = action.payload
        },
        [getPages.rejected]: (state, action) => {
            state.pages.status = "feiled"
        }
    }
})

export default pagesSlice;
