import axios from 'axios';

const axiosApi = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PROXY_DEV : process.env.REACT_APP_PROXY_PROD,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosApi.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  export default axiosApi;