import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getUrls = createAsyncThunk("urls/getUrls", async (urls) => {
  const getUrlsData = async(url) => {
    const domain = url.second_domain ? url.second_domain : url.main_domain
    return await caches.open(`${domain}`).then(async(Cache) => {
      return await Cache.match(`${domain}wp-json/data/v1/lps`)
        .then(async(res) => {
          //univerisits cache lps excist
          return await res.json().then((data) => {
            return data;
          });
        })
        .catch((err) => {
          //univerisits cache lps not excist
          return Cache.add(`${domain}wp-json/data/v1/lps`).then(() => {
            return Cache.match(`${domain}wp-json/data/v1/lps`).then(
              (res) => {
                //univerisits cache lps excist
                return res.json().then((data) => {
                  return data;
                });
              }
            );
          }).catch((error)=> {
            return []
          });
        });
    }).catch((error)=> {
      return []
    });
  };
  
  const getUrlsLoop = async() => {
    const withCoords = await Promise.all(urls.map(url => getUrlsData(url)));
    return withCoords;
  }
  
  const urlsFinal = getUrlsLoop();
  return urlsFinal;
});


const initialState = {
  urls: {
    status: null,
    data: {},
  },
};

const urlsSlice = createSlice({
  name: "urls",
  initialState,
  extraReducers: {
    [getUrls.pending]: (state, action) => {
      state.urls.status = "loading";
    },
    [getUrls.fulfilled]: (state, action) => {
      state.urls.status = "success";
      state.urls.data = action.payload;
    },
    [getUrls.rejected]: (state, action) => {
      state.urls.status = "feiled";
    },
  },
});

export default urlsSlice;
