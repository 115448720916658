import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getFreemium = createAsyncThunk('freemium/getFreemium', async (url, thunkAPI) => {
  return await caches.open(`${url}`).then(function (Cache) {
    return Cache.match(`${url}wp-json/tfa/v1/data/all`)
      .then((res) => {
        console.log(res);
        //univerisits cache lps excist
        return res.json().then((data) => {
          return data;
        });
      })
      .catch((err) => {
        //univerisits cache lps not excist
        return Cache.add(`${url}wp-json/tfa/v1/data/all`).then(() => {
          return Cache.match(`${url}wp-json/tfa/v1/data/all`).then((res) => {
            //univerisits cache lps excist
            return res.json().then((data) => {
              return data;
            });
          });
        });
      });
  });
});

const initialState = {
  freemium: {
    status: null,
    data: {},
  },
};

const freemiumSlice = createSlice({
  name: 'freemium',
  initialState,
  extraReducers: {
    [getFreemium.pending]: (state, action) => {
      state.freemium.status = 'loading';
    },
    [getFreemium.fulfilled]: (state, action) => {
      state.freemium.status = 'success';
      state.freemium.data = action.payload;
    },
    [getFreemium.rejected]: (state, action) => {
      state.freemium.status = 'feiled';
    },
  },
});

export default freemiumSlice;
