import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getLps = createAsyncThunk("lps/getLps", async (url, thunkAPI) => {

  return await caches.open(`${url}`).then(function (Cache) {
    
    return Cache.match(`${url}wp-json/data/v1/lps`).then(res=>{
      //univerisits cache lps excist
      return res.json().then(data => {
        return data
      })

    }).catch(err=>{
      //univerisits cache lps not excist
      return Cache.add(`${url}wp-json/data/v1/lps`).then(() => {
        return Cache.match(`${url}wp-json/data/v1/lps`).then(res=>{
            //univerisits cache lps excist
            return res.json().then(data => {
              return data
            })
      
          })
      });
    })
    
  });
});

const initialState = {
  lps: {
    status: null,
    data: {},
  },
};

const lpsSlice = createSlice({
  name: "lps",
  initialState,
  extraReducers: {
    [getLps.pending]: (state, action) => {
      state.lps.status = "loading";
    },
    [getLps.fulfilled]: (state, action) => {
      state.lps.status = "success";
      state.lps.data = action.payload;
    },
    [getLps.rejected]: (state, action) => {
      state.lps.status = "feiled";
    },
  },
});

export default lpsSlice;
