import moment from "moment";
import _ from "lodash";
import { Chart } from "primereact/chart";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLeads } from "../../store/watchdog/watchdogThunk";

export default function Watchdog() {
  const dispatch = useDispatch();

  const leads = useSelector((state) => state.leads.leads.data);
  const status = useSelector((state) => state.leads.leads.status);

  useEffect(() => {
    dispatch(getLeads());
  }, [dispatch]);

  //sum dates leads

  const todayLeads = _.filter(leads, function (n) {
    const todayCreate = moment(n.created_at).format("L");
    const today = moment().format("L");
    return moment(todayCreate).isSame(today);
  });
  const yesterdayLeads = _.filter(leads, function (n) {
    const yesterdayCreate = moment(n.created_at).format("L");
    const yesterday = moment().subtract(1, "day").format("L");
    return moment(yesterdayCreate).isSame(yesterday);
  });
  const weekLeads = _.filter(leads, function (n) {
    const dayCreate = moment(n.created_at).format("L");
    const startWeek = moment().startOf("isoweek").format("L");
    const today = moment().format("L");
    return moment(dayCreate).isBetween(startWeek, today, "days", "[]");
  });

  const monthLeads = leads.length;

  // new arrays

  const countLeadsMount = _.map(leads, "coursename");
  const countLeadsWeek = _.map(weekLeads, "coursename");
  const countLeadsYesterday = _.map(yesterdayLeads, "coursename");
  const countLeadsToday = _.map(todayLeads, "coursename");

  const coursenameUniq = _.uniq(countLeadsMount);
  const universityUniq = _.uniq(
    _.map(coursenameUniq, function (n) {
      return n.replace(/\-.*/, "");
    })
  );

  let arrUni = [];
  let uniSort = _.sortBy(universityUniq);
  let indexOfUni = null;

  const courseLeads = (course, count) => {
    const arrCourse = [];
    
    count.map((value, key) => {
      const clean = value.replace(/\-.*/, "");
      
      if (!_.includes(arrUni, clean)) {
        indexOfUni = _.indexOf(uniSort, clean);
        arrUni[indexOfUni]=clean;
        arrCourse[indexOfUni]=0;
      }

      let newIndex = _.indexOf(arrUni, clean);
      let courseVal = value.replace(/.*\-/, "");
      if (courseVal === course) {
        
        arrCourse[newIndex] += 1;
      }

    });
    arrUni = [];
    return arrCourse;
  };


  const getChartData = (count) => {
    const data = {
      labels: uniSort,
      datasets: [
        {
          type: "bar",
          label: "CS",
          backgroundColor: "#35b8e0",
          data: courseLeads("CS", count),
        },
        {
          type: "bar",
          label: "DM",
          backgroundColor: "#dd9933",
          data: courseLeads("DM", count),
        },
        {
          type: "bar",
          label: "UX",
          backgroundColor: "#f56cb3",
          data: courseLeads("UX", count),
        },
        {
          type: "bar",
          label: "SD",
          backgroundColor: "#8224e3",
          data: courseLeads("FS", count),
        },
        {
          type: "bar",
          label: "DSA",
          backgroundColor: "#00a38d",
          data: courseLeads("DS", count),
        },
      ],
    }
    return data
  }


  const stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          color: "#ffffff",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#ffffff",
        },
        grid: {
          color: "transparent",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#ffffff",
        },
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">SUM</a>
                    </li>
                    <li className="breadcrumb-item active">Watchdog</li>
                  </ol>
                </div>
                <h4 className="page-title">Watchdog</h4>
              </div>
            </div>
          </div>
          {status === "success" && (
            <>
              <div className="row">
                <div className="col-3">
                  <div className="card">
                    <div className="card-body widget-user">
                      <div className="text-center">
                        <h2
                          className="fw-normal text-success"
                          data-plugin="counterup"
                        >
                          {todayLeads.length}
                        </h2>
                        <h5>Today</h5>
                        <i>{moment().format("L")}</i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card">
                    <div className="card-body widget-user">
                      <div className="text-center">
                        <h2 className="fw-normal text-pink" data-plugin="counterup">
                          {yesterdayLeads.length}
                        </h2>
                        <h5>Yesterday</h5>
                        <i>{moment().subtract(1, "day").format("L")}</i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card">
                    <div className="card-body widget-user">
                      <div className="text-center">
                        <h2
                          className="fw-normal text-primary"
                          data-plugin="counterup"
                        >
                          {weekLeads.length}
                        </h2>
                        <h5>This Week</h5>
                        <i>
                          {moment().clone().startOf("isoweek").format("L")} -{" "}
                          {moment().format("L")}
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="card">
                    <div className="card-body widget-user">
                      <div className="text-center">
                        <h2 className="fw-normal text-info" data-plugin="counterup">
                          {monthLeads}
                        </h2>
                        <h5>This Month</h5>
                        <i>
                          {moment().clone().startOf("month").format("L")} -{" "}
                          {moment().format("L")}
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h5>
                        Today Leads{" "}
                        <span className="text-success">
                          {todayLeads.length}
                        </span>
                      </h5>
                      <i>{moment().format("L")} | Per University & Course</i>
                      <Chart
                        type="bar"
                        data={getChartData(countLeadsToday)}
                        options={stackedOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h5>
                        Yesterday Leads{" "}
                        <span className="text-success">
                          {yesterdayLeads.length}
                        </span>
                      </h5>
                      <i>
                        {moment().subtract(1, "day").format("L")} | Per
                        University & Course
                      </i>
                      <Chart
                        type="bar"
                        data={getChartData(countLeadsYesterday)}
                        options={stackedOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h5>
                        This Week Leads{" "}
                        <span className="text-success">{weekLeads.length}</span>
                      </h5>
                      <i>
                        {moment().clone().startOf("isoweek").format("L")} -{" "}
                        {moment().format("L")} | Per University & Course
                      </i>
                      <Chart
                        type="bar"
                        data={getChartData(countLeadsWeek)}
                        options={stackedOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h5>
                        This Month Leads{" "}
                        <span className="text-success">{monthLeads}</span>
                      </h5>
                      <i>
                        {moment().clone().startOf("month").format("L")} -{" "}
                        {moment().format("L")} | Per University & Course
                      </i>
                      <Chart
                        type="bar"
                        data={getChartData(countLeadsMount)}
                        options={stackedOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
